import * as React from 'react';
import KeyCode from "rc-util/es/KeyCode";
var ESC = KeyCode.ESC,
    TAB = KeyCode.TAB;
export default function useAccessibility(_ref) {
  var visible = _ref.visible,
      setTriggerVisible = _ref.setTriggerVisible,
      triggerRef = _ref.triggerRef,
      menuRef = _ref.menuRef,
      onVisibleChange = _ref.onVisibleChange;
  var focusMenuRef = React.useRef(false);

  var handleCloseMenuAndReturnFocus = function handleCloseMenuAndReturnFocus() {
    if (visible && triggerRef.current) {
      var _triggerRef$current, _triggerRef$current$t, _triggerRef$current$t2, _triggerRef$current$t3;

      (_triggerRef$current = triggerRef.current) === null || _triggerRef$current === void 0 ? void 0 : (_triggerRef$current$t = _triggerRef$current.triggerRef) === null || _triggerRef$current$t === void 0 ? void 0 : (_triggerRef$current$t2 = _triggerRef$current$t.current) === null || _triggerRef$current$t2 === void 0 ? void 0 : (_triggerRef$current$t3 = _triggerRef$current$t2.focus) === null || _triggerRef$current$t3 === void 0 ? void 0 : _triggerRef$current$t3.call(_triggerRef$current$t2);
      setTriggerVisible(false);

      if (typeof onVisibleChange === 'function') {
        onVisibleChange(false);
      }
    }
  };

  var handleKeyDown = function handleKeyDown(event) {
    var _menuRef$current;

    switch (event.keyCode) {
      case ESC:
        handleCloseMenuAndReturnFocus();
        break;

      case TAB:
        if (!focusMenuRef.current && ((_menuRef$current = menuRef.current) === null || _menuRef$current === void 0 ? void 0 : _menuRef$current.focus)) {
          event.preventDefault();
          menuRef.current.focus();
          focusMenuRef.current = true;
        } else {
          handleCloseMenuAndReturnFocus();
        }

        break;
    }
  };

  React.useEffect(function () {
    if (visible) {
      window.addEventListener('keydown', handleKeyDown);
      return function () {
        window.removeEventListener('keydown', handleKeyDown);
        focusMenuRef.current = false;
      };
    }

    return function () {
      return null;
    };
  }, [visible]); // eslint-disable-line react-hooks/exhaustive-deps
}