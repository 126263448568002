// @ts-ignore
import ReactDOM from 'react-dom';
var createNode = function (children, type) {
    var mountPoint = document.createElement('div');
    if (type === 'tooltip') {
        mountPoint.className = 'g2-tooltip';
    }
    ReactDOM.render(children, mountPoint);
    return mountPoint;
};
export default createNode;
